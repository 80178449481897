<template>
	<div class="categoriesWrapper">
		<div>
			<Categories :editable="true" />
		</div>
	</div>
</template>

<script>
	import { ref, computed } from "vue"
	import Categories from "./Categories"

	export default {
		name: "CategoriesAdmin",
		components: { Categories }
	}
</script>

<style lang="scss">
	.categoriesWrapper > div {
		width: 100%;
		height: 100%;
		overflow: scroll;
		padding: 0 $atom * 5;
	}
</style>
