<template>
	<div class="categoriesTree" :class="{ dragging, editable }">
		<button v-if="editable" class="addRoot ba ph-5" @click="add()">Add Root Node</button>
		<Tree
			ref="treeEl"
			:value="data"
			:indent="20"
			:ondragstart="() => (dragging = true)"
			:ondragend="onNodeDragEnd"
			:edgeScroll="true"
			:edgeScrollTriggerMargin="100"
			:unfoldWhenDragover="false"
			:draggable="editable && !editing"
			edgeScrollTriggerMode="top_left_corner"
		>
			<template v-slot="{ node, path, tree }">
				<div class="verticalBar" :style="`left: ${path.length * 20}px`" />
				<div
					class="node"
					:class="{
						editing: editing === node.id,
						selected: modelValue.includes(node.id),
						off: !node.visible
					}"
					@click="select(node, path, tree)"
				>
					<input
						v-if="editing === node.id"
						type="text"
						ref="titleInputEl"
						v-model="pendingTitle"
						:disabled="editing !== node.id"
						@click.stop
					/>
					<div class="title" v-else>
						<input
							type="checkbox"
							:checked="!node.$folded"
							:class="{ visible: node.children && node.children.length }"
							@click.stop="tree.toggleFold(node, path)"
						/>
						<span>{{ node.title }}</span>
					</div>
					<div class="ctrls">
						<template v-if="editing === node.id">
							<Richbutton theme="prim" @click.stop="save(node, path, tree)" :working="working"
								>Save</Richbutton
							>
							<button :disabled="working" @click.stop="cancelEdit(node, path, tree)">Cancel</button>
						</template>
						<template v-else-if="!editing">
							<button @click.stop="edit(node)" :disabled="working">Edit</button>
							<button @click.stop="add(node, path, tree)" :disabled="working">Add</button>
							<button
								v-if="node.visible"
								class="txt clr-grn"
								@click="setVisible(false, node, path, tree)"
							>
								On
							</button>
							<button v-else class="txt clr-red" @click="setVisible(true, node, path, tree)">Off</button>
							<button
								v-if="(!node.children || !node.children.length) && !working"
								@click.stop="destroy(node, path, tree)"
								class="x ml-4"
							/>
							<Spinner v-else-if="working" class="ml-4" />
						</template>
					</div>
				</div>
			</template>
		</Tree>
	</div>
</template>

<script>
	import { isEqual, without, uniq } from "lodash"
	import { ref, computed, watch, onMounted, onBeforeUnmount, nextTick } from "vue"
	import { Tree, Fold, Draggable, foldAll, walkTreeData } from "he-tree-vue"
	import { api, alertsList, categories } from "@/store"
	import { replaceKeysDeep, arrToKeyedObj, emitter } from "@/utils"
	import { Richbutton, Spinner } from "@/components"

	export default {
		name: "CategoriesTree",
		components: { Tree: Tree.mixPlugins([Draggable, Fold]), Richbutton, Spinner },
		props: {
			modelValue: { type: Array, default: () => [] },
			editable: { type: Boolean, default: false }
		},
		setup(props, ctx) {
			const alerts = alertsList()
			const store = categories()
			const focusTitle = () => nextTick(() => titleInputEl.value.focus())
			const editing = ref(false)
			const working = ref(false)
			const dragging = ref(false)
			const pendingTitle = ref("")
			const titleInputEl = ref(null)
			const treeEl = ref(null)

			const expandPath = (path) => {
				const values = []
				treeEl.value.getAllNodesByPath(path).forEach((node) => {
					node.$folded = false
					values.push(node.id)
				})
				return values
			}

			const pushSelected = (ids) => {
				if (!Array.isArray(ids)) ids = [ids]
				const value = uniq([...props.modelValue, ...ids])
				ctx.emit("update:modelValue", value)
			}

			const popSelected = (ids) => {
				if (!Array.isArray(ids)) ids = [ids]
				const value = without(props.modelValue, ...ids)
				ctx.emit("update:modelValue", value)
			}

			const init = () => {
				foldAll(store.data.value)
				const ids = []
				walkTreeData(store.data.value, (node, index, parent, path) => {
					if (props.modelValue.includes(node.id)) ids.push(...expandPath(path))
				})
				pushSelected(ids)
			}

			const fetch = async () => {
				if (!store.data.value.length) await store.fetch()
				nextTick(init)
			}

			const save = (node, path, tree) => {
				working.value = true
				if (node.id !== "pending") {
					const payload = { title: pendingTitle.value }
					api.put(`admin/exercises/categories/${node.id}`, { json: payload })
						.then((res) => {
							node.title = res.title
						})
						.finally(() => {
							editing.value = false
							working.value = false
						})
				} else {
					const parent = tree.getNodeParentByPath(path)
					const parentId = parent ? parent.id : store.rootId.value
					const payload = { title: pendingTitle.value, parentId }
					api.post(`admin/exercises/categories`, { json: payload })
						.then((res) => {
							Object.assign(node, res)
						})
						.finally(() => {
							editing.value = false
							working.value = false
						})
				}
			}

			const destroy = (node, path, tree) => {
				working.value = true
				api.del(`admin/exercises/categories/${node.id}`)
					.then(() => tree.removeNodeByPath(path))
					.catch(() => alerts.push("Cannot delete folder", "neg"))
					.finally(() => (working.value = false))
			}

			const select = (node, path, tree) => {
				if (editing.value) return
				let value
				if (props.modelValue.includes(node.id)) {
					const ids = []
					const fn = (node) => {
						if (props.modelValue.includes(node.id)) ids.push(node.id)
						if (node.children) node.children.forEach(fn)
					}
					fn(node)
					popSelected(ids)
				} else {
					pushSelected(tree.getAllNodesByPath(path).map((node) => node.id))
					if (node.$folded) node.$folded = false
				}
			}

			const setVisible = async (visible, node, path, tree) => {
				if (editing.value) return
				await api.put(`admin/exercises/categories/${node.id}/visible`, { json: { visible } })
				if (visible) {
					// make ancestors visible
					tree.getAllNodesByPath(path).forEach((node) => (node.visible = true))
				} else {
					// make children invisible
					const fn = (node) => {
						node.visible = false
						if (node.children) node.children.forEach(fn)
					}
					fn(node)
				}
			}

			const add = (node) => {
				if (editing.value) return
				if (node) {
					if (!node.children) node.children = []
					if (node.$folded) node.$folded = false
					node.children.unshift({ id: "pending", title: "Untitled" })
				} else {
					store.data.value.unshift({ id: "pending", title: "Untitled" })
				}
				editing.value = "pending"
				pendingTitle.value = "Untitled"
				focusTitle()
			}

			const edit = (node) => {
				editing.value = node.id
				pendingTitle.value = node.title
				focusTitle()
			}

			const cancelEdit = (node, path, tree) => {
				if (editing.value === "pending") tree.removeNodeByPath(path)
				editing.value = false
			}

			const onNodeDragEnd = (tree, str) => {
				dragging.value = false
				const start = str.startPath
				const target = str.targetPath
				if (isEqual(start, target)) return false
				const orig = tree.getNodeByPath(start)
				const parent = tree.getNodeParentByPath(target)
				if (orig.visible && parent && !parent.visible) return false
				const index = target.at(-1)
				if (index > 0) {
					const n = [...target]
					const last = n.pop()
					n.push(last - 1)
					const node = tree.getNodeByPath(n)
					api.put(`admin/exercises/categories/${orig.id}/toRightOf/${node.id}`)
				} else if (index === 0) {
					if (!parent) {
						// root level, top
						const node = tree.getNodeByPath(target)
						api.put(`admin/exercises/categories/${orig.id}/toLeftOf/${node.id}`)
					} else if (!parent.children || !parent.children.length) {
						api.put(`admin/exercises/categories/${orig.id}/into/${parent.id}`)
					} else {
						const node = tree.getNodeByPath(target)
						api.put(`admin/exercises/categories/${orig.id}/toLeftOf/${node.id}`)
					}
				}
			}

			onMounted(() => {
				fetch()
			})

			return {
				...store,
				add,
				save,
				destroy,
				select,
				edit,
				setVisible,
				cancelEdit,
				editing,
				working,
				onNodeDragEnd,
				pendingTitle,
				titleInputEl,
				treeEl,
				dragging,
				init
			}
		}
	}
</script>

<style lang="scss">
	.categoriesTree {
		/*	overflow: scroll; */
		> div {
			transform-origin: top left;
		}
		.addRoot {
			margin-left: -$atom * 5;
		}
		.node {
			position: relative;
			display: flex;
			align-items: flex-start;
			min-height: $ctrl-ht;
			input[type="text"] {
				flex: 1;
				background: transparent;
			}
			.title {
				position: relative;
				z-index: 5;
				display: flex;
				flex: 1;
				margin-left: $atom * 6 + 1;
				> span {
					position: relative;
					display: flex;
					align-items: center;
					min-height: $ctrl-ht;
					@include ph(6);
					@include pv(2);
					margin-left: -9px;
					/* cursor: grab; */
				}

				input[type="checkbox"] {
					position: absolute;
					left: -19px;
					top: 50%;
					margin-top: -9px;
					z-index: 10;
					visibility: hidden;
					&.visible {
						visibility: visible;
					}
					&:before {
						position: absolute;
						top: -50%;
						left: -50%;
						width: 200%;
						height: 200%;
						content: "";
						opacity: 0;
					}
				}
			}
			.ctrls {
				opacity: 0;
				pointer-events: none;
				flex: 1;
				> button {
					margin-left: 1px;
					flex: 0;
					&:not(.x) {
						@include ph(6);
					}
					&.x {
						@include mh(4);
					}
				}
			}
			&.editing {
				> input[type="text"] {
					position: relative;
					z-index: 5;
					background: white;
					@include ba;
					@include ph(6);
					margin-left: 9px;
				}
				> .ctrls {
					opacity: 1;
					pointer-events: auto;
				}
			}
			&.selected {
				.title > span {
					color: #fff;
					background: $clr-prim !important;
				}
			}
			&.drop {
				.title > span {
					color: $clr-drk;
					background: $clr-fcs;
				}
			}
			&.off {
				.title {
					color: $clr-dark !important;
				}
			}
			&:after {
				position: absolute;
				top: 0;
				left: 9px;
				width: 1px;
				height: 100%;
				background: $clr-brdr;
				content: "";
			}
			&:before {
				position: absolute;
				top: 50%;
				left: 9px;
				width: 8px;
				height: 1px;
				background: $clr-brdr;
				content: "";
			}
		}

		&:not(.dragging) .node:hover {
			.title,
			.title > span {
				background: $clr-neut-l1;
			}
			.ctrls {
				opacity: 1;
				pointer-events: auto;
			}
		}

		&:not(.editable) .ctrls {
			display: none;
		}

		.verticalBar {
			position: absolute;
			top: 0;
			width: 1px;
			height: 100%;
			background: $clr-brdr;
			margin-left: -11px;
		}

		.tree-branch {
			position: relative;
			&:last-child {
				> div > div > .verticalBar {
					display: none;
				}
				> div > div > .node:after {
					height: 50%;
				}
			}
			&:only-child {
				> * > * > .verticalBar {
					display: none;
				}
			}
		}

		.tree-placeholder-node {
			background: $clr-acc;
			height: $ctrl-ht;
			margin-left: 9px;
			position: relative;
		}

		.dragging .tree-node-back:hover {
			background-color: inherit;
		}

		.he-tree--hidden {
			display: none;
		}
	}
</style>
